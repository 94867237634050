import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../../components/Layout'
import { components } from '../../slices'


const WeekMenu = ({data}) => {
  console.log('WeekMenu data: ', data)
  if (!data) return null

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const {id, type, uid} = data.prismicMenuDay
  console.log(`${uid} ${type} ${id}`)
  const slices = data.prismicMenuDay.data
  const seo = {
    title: slices.seo_title,
    description: slices.seo_description,
    keywords: slices.seo_keywords,
  }
  console.log('seo: ', seo)
  const rests = data.prismicRestaurant.data.body[0].items
  // console.log(' > rests: ', rests)
  const cntx = {weekNumber: slices.week_number, rests}
  

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={seo}>
        <SliceZone slices={slices.body} components={components} context={cntx} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    prismicMenuDay(id: { eq: $id }) {
      _previewable
      id
      uid
      type
      tags
      url
      data {
        seo_title
        seo_description
        seo_keywords

        week_number
        body {
          ...MenuDayMainSlice
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
    prismicRestaurant(uid: {eq: "restaurants"}) {
      id
      type
      uid
      data {
        body {
          ... on PrismicRestaurantDataBodyRestaurantSlice {
            id
            slice_type
            slice_label
            items {
              code
              name
              location
              phone
              priority
              url
              image_url
              img {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(WeekMenu)